import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getColumnSearchProps } from "../../../utils/tables";
import moment from "moment";
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg";
import { Modal, Spin, Table } from "antd";
import styles from "../../monitor/styles/MonitorUI.module.css";
import HeaderList from "../../../components/HeaderList";
import OrderDetail from "../../../components/OrderDetail";
import { getPromosListThunk } from "../../../redux/slices/promosList";

const PromoUI = ({
  loadingOrder,
  openDetails,
  modalOrder,
  closeDetails,
  orderDetail,
}) => {
  const dispatch = useDispatch();
  const promosList = useSelector((state) => state.promosList);

  useEffect(() => {
    if(promosList.orders) return
    dispatch(getPromosListThunk())
  }, [])

  const columnsTakeOut = [
    {
      title: "Ticket",
      dataIndex: "ticket",
      ...getColumnSearchProps("ticket"),
      render: (val, record) => <span>{val}</span>,
    },
    {
      title: "Cliente",
      dataIndex: "id_user",
      render: (val) => `${val.name} ${val.lastname}`,
    },
    {
      title: "Código",
      dataIndex: "assignPromoCode",
      ...getColumnSearchProps("assignPromoCode"),
      align: "center",
    },
    {
      title: "Hora de compra",
      dataIndex: "created_time",
      align: "center",
      render: (val) => moment(val).format("DD/MM/YYYY hh:mm a"),
    },
    {
      title: "Teléfono",
      dataIndex: "id_user",
      align: "center",
      render: (val) => val.mobile,
    },
    {
      title: "Monto actual",
      dataIndex: "total",
      align: "center",
      render: (val) => `$${val}`,
    },
    {
      title: "Ver detalle",
      dataIndex: "id",
      align: "center",
      render: (val) => (
        <button
          disabled={loadingOrder}
          onClick={() => openDetails(val)}
          className={styles.eye}
        >
          {loadingOrder === val ? <Spin size="small" /> : <Eye />}
        </button>
      ),
    },
  ];

  return (
    (<main>
      <HeaderList back="/" title="PROMOCIÓN DÍA DEL NIÑO" />
      <Table
        columns={columnsTakeOut}
        scroll={{ x: "100%" }}
        rowKey={(record) => record.id}
        dataSource={promosList.orders}
      />
      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        open={modalOrder}
        width={1150}
        closable={false}
        onCancel={closeDetails}
      >
        <OrderDetail order={orderDetail} close={closeDetails} />
      </Modal>
    </main>)
  );
};

export default PromoUI;
