import instance from "./Request";

export const getOrders = async (branch, admin) => {
  const request = await instance();
  let data = await request.post(`/monitor/${branch}`, { isAdmin: admin}).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const getStatOrders = async (branch, admin) => {
  const request = await instance();
  let data = await request.post(`/monitor/stats/${branch}`, { isAdmin: admin}).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const getDetails = async (order) => {
  const request = await instance();
  let data = await request.get(`/monitor/order/${order}`).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

/* export const getLoyalty = async () => {
  const request = await instance();
  let data = await request.get("/main/promo-discount").catch((error) => {
    return {
      error,
    };
  });
  return data;
}; */

/* export const updateLoyalty = async (loyalty) => {
  const request = await instance();
  let data = await request
    .post("/main/promo-discount", loyalty)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
*/

export const getDrivers = async (location) => {
  const request = await instance();
  let data = await request
    .post("/main/driver-list", { location })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
}; 

export const updateNotes = async (user, address, notes, order) => {
  const request = await instance();
  let data = await request
    .post("/clients/add-addrnotes", {
      id: address,
      customer: user,
      info: notes,
      orderId: order,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const acceptOrder = async (order, branch) => {
  const request = await instance();
  let data = await request
    .post("/orders/status/order-being-prepared", {
      orderId: order,
      branchId: branch,
      estimatedTime: 20,
      delivery_by_partner: true,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const updateOrder = async (order) => {
  const request = await instance();
  let data = await request
    .post("orders/pick-up", { orderId: order })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const dispatchOrder = async (order) => {
  const request = await instance();
  let data = await request
    .post("orders/status/order-dispatched", { orderId: order })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const cancelOrder = async (order) => {
  const request = await instance();
  let data = await request
    .post("/orders/cancel-order", { orderId: order, canceledBy: "agent" })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const assignDriver = async (order, driver) => {
  const request = await instance();
  let data = await request
    .post("/order/assign-driver", { orderId: order, driverId: driver })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const finishOrder = async (order) => {
  const request = await instance();
  let data = await request
    .post("/orders/complete", { orderId: order })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const resendOrder = async (order, branch) => {
  const request = await instance();
  let data = await request
    .post("/orders/resend-order", { orderId: order, branchId: branch })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const sendPeya = async (order, branch) => {
  const request = await instance();
  let data = await request
    .post("/orders/status/send-to-peya", { orderId: order, branchId: branch })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const transferOrder = async (order, branch, comments = "") => {
  const request = await instance();
  let data = await request
    .post("/order/transfer", { orderId: order, location: branch, comments })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
