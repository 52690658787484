import Header from "../../../components/HeaderList";
import Clients from "../../../assets/sections/clients.png";
import Survey from "../../../assets/sections/survey.png";
import Users from "../../../assets/sections/users.png";
import Motos from "../../../assets/sections/drivers.png";
import Cobertura from "../../../assets/sections/coverage.png";
import CardSection from "../../../components/CardSection";
import styles from "../styles/SettingsUI.module.css";

const SettingsUI = () => (
  <div>
    <Header title="Ajustes" />
    <div className={styles.sections}>
      <CardSection image={Clients} label="CLIENTES" route="/ajustes/clientes" />
      {/* 
      <CardSection image={Cobertura} label="ZONAS DE COBERTURA" route="/ajustes/zonas" />
      <CardSection image={Motos} label="DRIVERS" route="/ajustes/drivers" />
      */}
      <CardSection image={Users} label="USUARIOS" route="/ajustes/usuarios" />
      {/*
      <CardSection
        image={Survey}
        label="CALIFICACIONES"
        route="/ajustes/calificaciones"
      />
       <CardSection
        image={Survey}
        label="PARÁMETROS"
        route="/ajustes/parametros"
      /> 
      <CardSection image={Motos} label="LISTA DE MOTOS" route="/ajustes/motos" /> */}
    </div>
  </div>
);

export default SettingsUI;
