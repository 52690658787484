import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import {
  handleExtraActive,
  handleExtra2Active,
  selectExtras,
  getAllExtras,
} from "../../redux/slices/products";
import { selectUser } from "../../redux/slices/user";
import CondimentUI from "./components/CondimentUI";

const Condiments = () => {
  const [loading, handleLoading] = useState(true);
  const dataextras = useSelector(selectExtras);
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const initialFetch = useCallback(async () => {
    handleLoading(true);
    const response = await dispatch(getAllExtras(user.branch));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, [dispatch, user]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const handleExtrasActive = async (idExtra, val, type, branch, cat) => {
    const response = await dispatch(handleExtraActive(idExtra, val, type, branch, cat));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };

  const handleExtras2Active = async (idExtra, val, type, branch, cat) => {
    const response = await dispatch(handleExtra2Active(idExtra, val, type, branch, cat));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };

  return (
    <CondimentUI
      loading={loading}
      extras={dataextras}
      initialFetch={initialFetch}
      handleExtrasActive={handleExtrasActive}
      handleExtras2Active={handleExtras2Active}
    />
  );
};

export default Condiments;
