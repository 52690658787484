import axios from 'axios';

export const fetchIP = async () => {
        try {
            const response = await axios.get("http://ip-api.com/json/");        
            console.log("response is : ", response);
            if(response.status === 200) {
                localStorage.setItem('ipInfo', JSON.stringify(response.data));
            } 
            else {
                localStorage.setItem('ipInfo', '');
                console.log('Request failed:', response.data);
            }
            return response.data;
        } 
        catch (error) {
            console.log(error);
            console.error('Error fetching IP:', error);
            return {};
        }
    };