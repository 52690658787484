import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const s2ab = (s) => {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

const exportData = (users) => {
  const wb = XLSX.utils.book_new();
  const title = "Reporte de clientes";

  wb.Props = {
    Title: title,
    Subject: "Reporte de clientes",
    Author: "Buffalo Wings",
    CreatedDate: new Date(),
  };
  wb.SheetNames.push("Clientes");
  const ws_data = [
    [
      "Nombre",
      "Apellido",
      "Teléfono",
      // 'DUI',
      "Correo",
      // 'Cumpleaños',
      "Estado",
    ],
  ];

  users.forEach((user) => {
    const newRegister = [
      user.name,
      user.lastname,
      user.phone,
      // user.identity,
      user.email,
      // user.birthday,
      user.active ? "Activo" : "Bloqueado",
    ];
    ws_data.push(newRegister);
  });
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets["Clientes"] = ws;
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  saveAs(
    new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
    `${title}.xlsx`
  );
};

export default exportData;
