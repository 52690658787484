import { useEffect, useRef, useState } from 'react';
import { useJsApiLoader, GoogleMap, Autocomplete, DrawingManagerF, InfoWindow, Marker, MarkerClusterer, Polygon, Circle } from '@react-google-maps/api';
import deleteIcon from '../assets/icons/trash.svg';
import mapStyles from './styles/mapStyles';
import styles from './styles/customMap.module.css';

const GOOGLE_MAPS_API_KEY="AIzaSyBov1Wd7NcaoQmrLU5xnnEFQ2ZBiokzurk";
const libraries = ['places', 'geometry','drawing'];

const MapZone = (props) => {
    const {
        polygons,
        markers,
        driversMarkers,
        branchesMarkers,
        defaultCenter,
        centerZoom,
        selectedDriverMarker,
        setSelectedDriverMarker,
        setPolygons,
        loadDraw
    } = props;
    const mapRef = useRef();
    const polygonRefs = useRef([]);
    const markerRefs = useRef([]);
    const activePolygonIndex = useRef();
    const autocompleteRef = useRef();
    const drawingManagerRef = useRef();
    var evento = null;

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries
    });

    const [loadBranchesMarkers, setLoadBranchesMarkers] = useState();    
    
    const defaultStyleOptions = {
        strokeOpacity: 0.5,
        strokeWeight: 2,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true
    };

    const closeStyleOptions = {
        ...defaultStyleOptions,
        zIndex: 3,
        fillOpacity: 0.05,
        strokeColor: "#ff5252",
        fillColor: "#ff5252"
    }

    const middleStyleOptions = {
        ...defaultStyleOptions,
        zIndex: 2,
        fillOpacity: 0.05,
        strokeColor: "#fbc02d",
        fillColor: "#fbc02d"
    }

    useEffect(()=>{
        
    }, [driversMarkers]);

    useEffect(()=>{
        const bmark = branchesMarkers.map((iterator, index) => {
            return (
            <>
                <Marker 
                    key={index}
                    onLoad={(event) => onLoadMarker(event, index)}
                    title={iterator.title}
                    position={iterator.position}
                    icon={iterator.icon}
                    options={iterator.markeroptions}
                />
                <Circle center={iterator.position} radius={1500} options={closeStyleOptions} />
                <Circle center={iterator.position} radius={3500} options={middleStyleOptions} />
            </>
        )});
        setLoadBranchesMarkers(bmark);
    }, []);

    const containerStyle = {
        width: '100%',
        height: '400px',
    }

    const autocompleteStyle = {
        boxSizing: 'border-box',
        border: '1px solid transparent',
        width: '240px',
        height: '38px',
        padding: '0 12px',
        borderRadius: '3px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        fontSize: '14px',
        outline: 'none',
        textOverflow: 'ellipses',
        position: 'absolute',
        right: '8%',
        top: '11px',
        marginLeft: '-120px',
    }

    const deleteIconStyle = {
        cursor: 'pointer',
        backgroundImage: `url(${deleteIcon})`,
        height: '24px',
        width: '24px',
        marginTop: '5px', 
        backgroundColor: '#fff',
        position: 'absolute',
        top: "2px",
        left: "52%",
        zIndex: 99999,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    }

    const polygonOptions = {
        fillOpacity: 0.3,
        fillColor: '#ff0000',
        strokeColor: '#ff0000',
        strokeWeight: 2,
        draggable: true,
        editable: true
    }

    const drawingManagerOptions = {
        polygonOptions: polygonOptions,
        drawingControl: true,
        drawingControlOptions: {
            position: window.google?.maps?.ControlPosition?.TOP_CENTER,
            drawingModes: [
                window.google?.maps?.drawing?.OverlayType?.POLYGON
            ]
        }
    }

    const onLoadMap = (map) => {
        mapRef.current = map;
    }

    const onLoadPolygon = (polygon, index) => {
        console.log("cargamos el poligono", index);
        console.log("cargamos val poligono", polygon);
        polygonRefs.current[index] = polygon;
    }

    const onLoadMarker = (marker, index) => {
        markerRefs.current[index] = marker;
    }

    const onClickPolygon = (index) => {
        console.log("Activamos el poligono", index);
        activePolygonIndex.current = index; 
    }

    const onLoadAutocomplete = (autocomplete) => {
        autocompleteRef.current = autocomplete;
    }

    const onPlaceChanged = () => {
        const { geometry } = autocompleteRef.current.getPlace();
        const bounds = new window.google.maps.LatLngBounds();
        if (geometry.viewport) {
            bounds.union(geometry.viewport);
        } else {
            bounds.extend(geometry.location);
        }
        mapRef.current.fitBounds(bounds);
    }

    const onLoadDrawingManager = drawingManager => {
        console.log("drawing loaded");
        drawingManagerRef.current = drawingManager;

        evento = window.google.maps.event.addListener(
            drawingManager, 'overlaycomplete', onOverlayComplete
        );
    }

    const onOverlayComplete = ($overlayEvent) => {
        console.log("Overlay Complete")
        // drawingManagerRef.current.setDrawingMode(null);
        const correla = polygons.length == 0?1:polygons.length+1;
        console.log($overlayEvent);
        if ($overlayEvent.type === window.google.maps.drawing.OverlayType.POLYGON) {
            const newPolygon = $overlayEvent.overlay.getPath()
                .getArray()
                .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }))

            // start and end point should be same for valid geojson
            const startPoint = newPolygon[0];
            newPolygon.push(startPoint);
            $overlayEvent.overlay?.setMap(null);
            setPolygons([...polygons, {id_polygon: correla, coord: newPolygon}]);
        }
        console.log('polygons loaded', polygons);
    }

    const onDeleteDrawing = () => {  
        console.log("on delte drawing");
        const filtered = polygons.filter((polygon, index) => index !== activePolygonIndex.current) 
        setPolygons(filtered)
    }

    const onEditPolygon = (index) => {
        console.log("on edito polygon", index);
        const polygonRef = polygonRefs.current[index];
        if (polygonRef) {
            const coordinates = polygonRef.getPath()
                .getArray()
                .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));

            let allPolygons = [...polygons];
            allPolygons[index] = { id_polygon: allPolygons[index].id_polygon, coord: coordinates};
            setPolygons(allPolygons)
        }
    }

    const handleCenterChanged = () => {
        if (mapRef.current) {
            const newCenter = mapRef.current.getCenter();
        }
    };

    console.log("load draw?", loadDraw);

    return (
        isLoaded
            ?
            <div className='map-container' style={{ position: 'relative' }}>
                {
                    loadDraw && drawingManagerRef.current
                    &&
                    <div
                        onClick={onDeleteDrawing}
                        title='Delete shape'
                        style={deleteIconStyle}>
                    </div>
                }
                <GoogleMap
                    zoom={15}
                    center={defaultCenter} 
                    onCenterChanged={handleCenterChanged}
                    onLoad={onLoadMap}
                    mapContainerStyle={containerStyle}
                    options={{ styles: mapStyles }}
                >
                    {/* --  VALIDAMOS SI USAMOS DIBUJO -- */}
                    { loadDraw && (
                        <DrawingManagerF
                            onLoad={onLoadDrawingManager}
                            options={drawingManagerOptions}
                            onUnmount={() => {
                                window.google.maps.event.removeListener(evento)
                            }}
                        />
                    )
                    }
                    {/* -- AGREGAMOS MARCADORES de Branches -- */}
                    { loadBranchesMarkers }
                    {/* -- AGREGAMOS MARCADORES de Drivers -- */}
                    { driversMarkers && (
                        <MarkerClusterer>
                            {   (clusterer) => {
                                    return driversMarkers.map((iterator, index) => {
                                        return (
                                        <Marker 
                                            key={index}
                                            title={iterator.title}
                                            position={iterator.position}
                                            icon={{url: iterator.icon.url, scaledSize: new window.google.maps.Size(40, 64)}}
                                            options={iterator.markeroptions}
                                            clusterer={clusterer}
                                            onLoad={(event) => onLoadMarker(event, index)}
                                            onClick={ () => {  setSelectedDriverMarker(iterator); }}
                                        />
                                    )})
                                }
                            }
                        </MarkerClusterer>
                        )
                    }
                    { selectedDriverMarker && (
                            <InfoWindow  
                                options={{pixelOffset: new window.google.maps.Size(0,-55) }}
                                position={selectedDriverMarker.position}
                                onCloseClick={() => { setSelectedDriverMarker(null) }}>
                                <div className='infoContainer'>
                                    <h3 className={styles.infoHead}>{selectedDriverMarker.title}</h3>
                                    <hr/>
                                    {selectedDriverMarker.orders.map(o => {
                                        return (
                                            <div key={o.id} className={styles.infoContent}>
                                                <div className={styles.infoText}><span>Ticket:</span> {o.ticket}</div>
                                                <div className={styles.infoText}><span>Pago:</span> {o.payment_method==1?"Efectivo":"Tarjeta"}</div>
                                                <div className={styles.infoText}><span>Monto:</span> $ {o.total.toFixed(2)}</div>
                                                {   o.payment_method==1 &&
                                                    <div className={styles.infoText}><span>Cambio para:</span> $ {o.cashback}</div>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                            </InfoWindow>
                        )
                    }                    
                    {/* -- AGREGAMOS POLIGONOS -- */}
                    {
                        polygons && (
                            polygons.map((iterator, index) => (
                            <Polygon
                                key={index}
                                onLoad={(event) => onLoadPolygon(event, index)}
                                onMouseDown={() => onClickPolygon(index)}
                                onMouseUp={() => onEditPolygon(index)}
                                onDragEnd={() => onEditPolygon(index)}
                                options={polygonOptions}
                                paths={iterator.coord}
                                draggable
                                editable
                            />
                        ))
                     )
                    }
                    <Autocomplete
                        onLoad={onLoadAutocomplete}
                        onPlaceChanged={onPlaceChanged}
                    >
                        <input
                            type='text'
                            placeholder='Search Location'
                            style={autocompleteStyle}
                        />
                    </Autocomplete>
                </GoogleMap>
            </div>
            :
            null
    );
}

export default MapZone; 