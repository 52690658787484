import { Form, Input, Select, Button, Row, Col } from "antd";
import { ReactComponent as Logo } from "../../../assets/logo/logo.svg";
import { ReactComponent as Person } from "../../../assets/icons/person.svg";
import { ReactComponent as Lock } from "../../../assets/icons/lock.svg";
import styles from "../styles/LoginUI.module.css";

const { Option } = Select;

const LoginUI = ({ login, sending, form }) => {
  
  const handleChange = () => {

  };
  return (
  <div className={styles.loginView}>
    <Row>
      <Col xs={0} lg={12}>
        <div className={styles.background}>
          <Logo className={styles.logoBackground} />
        </div>
      </Col>
      <Col xs={24} lg={12}>
        <div className={styles.form}>
          <Logo className={styles.logo} />
          <span className={styles.subtitle}>ADMINISTRADOR BW</span>
          <Form
            className={styles.info}
            onFinish={login}
            onFinishFailed={login}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              name="user"
              rules={[{ required: true, message: "Ingresa tu usuario" }]}
            >
              <Input
                prefix={<Person className={styles.icon} />}
                bordered={false}
                size="large"
                className={styles.input}
                placeholder="Usuario"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Ingresa tu contraseña" }]}
            >
              <Input.Password
                prefix={<Lock className={styles.icon} />}
                bordered={false}
                size="large"
                className={styles.input}
                visibilityToggle={false}
                placeholder="Contraseña"
              />
            </Form.Item>
            <Form.Item
              name="country"
              rules={[{ required: true, message: "Selecciona el pais" }]}
            >
            <Select
              style={{
                width: '100%',
              }}
              placeholder="Selecciona Pais"
              defaultValue={['ni']}
              onChange={handleChange}
              optionLabelProp="label"
              className={styles.input}
            >
              <Option value="sv" label="El Salvador">
                <div className="demo-option-label-item">
                  <span role="img" aria-label="El Salvador">
                  🇸🇻
                  </span> 
                  El Salvador
                </div>
              </Option>
              <Option value="ni" label="Nicaragua">
                <div className="demo-option-label-item">
                  <span role="img" aria-label="Nicaragua">
                  🇳🇮
                  </span> 
                  Nicaragua
                </div>
              </Option>
            </Select>
            </Form.Item>
            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={sending}
                className={styles.submit}
              >
                INGRESAR
              </Button>
            </Form.Item>
          </Form>
          <div className={styles.powered}>
            <a
              href="https://www.expresateweb.com/"
              target="_blank"
              rel="noreferrer"
            >
              {/* <span>Powered by</span> */}
              <img alt="" src="https://expresate.io/images/logo.svg" />
            </a>
          </div>
        </div>
      </Col>
    </Row>
  </div>
);
};

export default LoginUI;
