import { Spin, Select, DatePicker, Button, Table, Modal } from "antd";
import moment from "moment";
import cx from "classnames";
import {getColumnSearchProps} from '../../../utils/tables';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import Header from "../../../components/HeaderList";
import styles from "../styles/RatingsUI.module.css";
import Editor from "./Editor";

const { RangePicker } = DatePicker;
const { Option } = Select;

const RatingsUI = (props) => {
  const {
    loading,
    ratings,
    average,
    platform,
    setPlatform,
    confirmDelete,
    handleQuestion,
    openEditor,
    editorVisible,
    closeEditor,
  } = props;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: "Indicador",
      dataIndex: "question",
      ...getColumnSearchProps('question'),
    },
    {
      title: "Fecha Creacion",
      dataIndex: "dateBegin",
      render: (val) => moment(val).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Estatus",
      dataIndex: "active",
      render: (val) => val==1?"Activa":"Inactiva",
    },
    {
      title: 'Accion',
      align: 'center',
      dataIndex: 'id',
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => openEditor(record)}>
            <Edit />
          </div>
          <div className={styles.divider} />
          <div className={styles.delete} onClick={() => confirmDelete(id)}>
            <Trash />
          </div>
        </div>
      ),
    }
  ];

  return (
    (<div className={styles.view}>
      <Header
        title="Encuesta"
        back="/ajustes"
        actions={
          <div>
            <Select
              className={cx(styles.input, styles.select)}
              loading={loading}
              disabled={loading}
              size="large"
              value={platform}
              onChange={setPlatform}
            >
              <Option value={3}>Delivery</Option>
              <Option value={2}>Take Out</Option>
            </Select>
            <Button
              className={styles.newQuestion}
              size="large"
              type="primary"
              onClick={() => {}}
            >
              Nuevo Indicador
            </Button>
          </div>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          {/*
          <div className={styles.header}>
            <div className={styles.titleWrap}>
              <span className={styles.label}>PREGUNTA</span>
              <span className={styles.title}>{ratings.question}</span>
            </div>
            <Button
              className={styles.search}
              size="large"
              type="primary"
              onClick={() => {}}
            >
              Exportar
            </Button>
          </div>
      */}
          <div className={styles.bodyRatings}>
            <div className={styles.scores}>
              <div className={styles.scoreWrap}>
                <div className={styles.score}>{average}</div>
                <span className={styles.scoreLabel}>
                  PROMEDIO DE CALIFICACIÓN
                </span>
              </div>
              {/*
              <div className={styles.scoreWrap}>
                <div className={styles.score}>{ratings[0].ratings.length}</div>
                <span className={styles.scoreLabel}>TOTAL DE RESPUESTAS</span>
              </div>
              */}
            </div>
          </div>
          <Table columns={columns} dataSource={ratings.ratings} rowKey="id" />
        </div>
      )}
      <Modal
        destroyOnClose
        closable={false}
        open={editorVisible}
        footer={false}
        onCancel={closeEditor}
        width={600}
      >
        <Editor
          close={closeEditor}
          handleQuestion={handleQuestion}
        />
      </Modal>
    </div>)
  );
};

export default RatingsUI;
