import cx from 'classnames';
import moment from 'moment';
import styles from '../styles/ProductCard.module.css';

const ProductCard = ({order, openDetails}) => (
  <div className={styles.card} onClick={() => openDetails(order.id)}>
    <div className={styles.info}>
      <div className={styles.item}>
        <span className={styles.label}>Ticket</span>
        <span className={styles.value}>{order.ticket}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.label}>Destino</span>
        <span className={styles.value}>
          {`${order.Address ? order.Address.locality.name: ""}, ${order.Address ? order.Address.locality.departmentName : ""}`}
        </span>
      </div>
    </div>
    <div className={styles.amounts}>
      <div className={styles.item}>
        <span className={styles.label}>Hora</span>
        <span className={styles.value}>
          {moment(order.created_time).format('hh:mma')}
        </span>
      </div>
      <div className={styles.item}>
        <span className={styles.label}>Monto</span>
        <span className={cx(styles.value, styles.total)}>
          {order.currency}{parseFloat(order.total).toFixed(2)}
        </span>
      </div>
    </div>
  </div>
);

export default ProductCard;
