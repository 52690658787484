import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import * as produce from "immer";
import {
  selectNewOrders,
  selectDayOrders,
  getOrderDetails,
  getOrderStats,
  selectStatistics,
  selectLoaded,
  getMonitorOrders,
  getBranchDrivers,
  setLoaded,
} from "../../redux/slices/monitor";
import { selectUser } from "../../redux/slices/user";
import DeliverydashUI from "./components/DeliverydashUI";

const DeliveryDash = () => {
  const [modalOrder, handleModalOrder] = useState(false);
  const [loadingOrder, handleLoadingOrder] = useState(false);
  const [orderDetail, handleOrderDetail] = useState({});
  const newOrders = useSelector(selectNewOrders);
  const dayOrders = useSelector(selectDayOrders);
  const statistics = useSelector(selectStatistics);
  const loaded = useSelector(selectLoaded);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const initialFetch = useCallback(async () => {
    const [response] = await Promise.all([
      dispatch(getMonitorOrders(user.branch, user.admin)),
      dispatch(getOrderStats(user.branch, user.admin))
      // dispatch(getPromosListThunk()) // para mostrar las promos dia del nino
    ]);
    if (response.status !== "success") {
      message.error("Hubo un problema");
    }
  }, [dispatch, user]);

  useEffect(() => {
    const getEverything = async () => {
      await initialFetch();
      dispatch(setLoaded(true));
    };
    getEverything();
  }, [dispatch, initialFetch]);

  useEffect(() => {
    const countup = setInterval(initialFetch, 30000);

    return () => {
      clearInterval(countup);
    };
    // initialFetch();
  }, [initialFetch]);

  const openDetails = async (order) => {
    handleLoadingOrder(order);
    const response = await dispatch(getOrderDetails(order));
    handleLoadingOrder(false);
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } 
    else {
      handleOrderDetail(response.order);
      handleModalOrder(true);
    }
  };

  const closeDetails = () => {
    handleModalOrder(false);
    handleOrderDetail({});
  };

  const getDriversBranch = async (location) => {
    const response = await dispatch(getBranchDrivers(location));
    if (response.status !== "success") {
      message.error("Hubo un problema");
      return [];
    }
    return response.drivers;
  };

  const isAdmin = user.admin;
  const isDriver = user.driver;
  
  return (
    <DeliverydashUI
      modalOrder={modalOrder}
      openDetails={openDetails}
      loadingOrder={loadingOrder}
      newOrders={newOrders}
      dayOrders={dayOrders}
      statistics={statistics}
      // branches={branches}
      loaded={loaded}
      user={user}
      isAdmin={isAdmin}
      orderDetail={orderDetail}
      closeDetails={closeDetails}
      getDriversBranch={getDriversBranch}
    />
  );
};

export default DeliveryDash;
