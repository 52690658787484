import { Form, Input } from 'antd'
import React from 'react'

const ParamsUi = () => {
    // carta de parametros en ajustes
    // mandarlo a otra vista y mostra de una inico un forma para editar parametros de la api

  return (
    <div>
        <Form
        layout='vertical'
        >
            <Form.Item label='Habilitación de invitado'>
                <Input />
            </Form.Item>
            <Form.Item label='Invitado puede usar el código'>
                <Input />
            </Form.Item>
        </Form>
    </div>
  )
}

export default ParamsUi