import axios from "axios";

const GetInstance = async (file) => 
{
  try 
  {
    // const ipAddress = JSON.parse(localStorage.getItem('ipInfo'));
    const baseURL = "https://apini.gobuff.com"; // URL de Produccion Nicaragua
    // const baseURL = "https://buffaloapi.expresatebox.io"; // URL de Pruebas
    const timeout = 120000;
    const request = axios.create({
      baseURL,
      timeout,
      headers: {
        "Content-Type": file ? "multipart/form-data" : "application/json",
        // country: ipAddress.countryCode.toLowerCase(),
        country: 'ni'
      },
    });

    request.interceptors.request.use(
      async (config) => {
        const token = localStorage.getItem("buffalo_admin");
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
    return request;
  }
  catch(error) {
    console.log(error);
    return {};
  }
};

export default GetInstance;
