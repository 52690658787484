import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import { selectUser } from "../../redux/slices/user";
import { getAllVehicles, selectVehicles } from '../../redux/slices/vehicles';
import { getAllBranches, selectBranches } from "../../redux/slices/branches";
import { selectDayOrders, getOrderDetails, getMonitorOrders } from "../../redux/slices/monitor";
import DriversUI from "./components/DriversUI";
import moment from "moment-timezone";

const Drivers = () => {
    const [loading, handleLoading] = useState(true);
    const [zoom, setZoom] = useState(10);
    const [coords, setCoordinates] = useState([]);
    const [latlng_center, setLatLngCenter] = useState({lat: 13.6723755493, lng:-89.310822749 });
    const driverList = useSelector(selectVehicles);
    const dayOrders = useSelector(selectDayOrders);
    const branches = useSelector(selectBranches);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    const initialFetch = useCallback(async () => {
        const [response, response_branch, response_orders] = await Promise.all([ 
            dispatch(getAllVehicles()),
            dispatch(getAllBranches(user.id)),
            dispatch(getMonitorOrders(user.branch, user.admin)),
        ]);
        if (response.status !== 'success') {
            message.error('¡Hubo un problema!');
        }
    }, [dispatch, user]);

    useEffect(() => {
        const getEverything = async () => {
            await initialFetch();
            handleLoading(false);
        };
        getEverything();
    }, [dispatch]);

    useEffect(() => {
        const countup = setInterval(initialFetch, 35000);
        return () => {
            clearInterval(countup);
        };
    }, [initialFetch]);

    const loadDriverMap = (lat, lng) => {
        console.log(" Loading driver in map");
        setLatLngCenter({ lat, lng });
        setZoom(14);
        return false;
    }

    return (
        <DriversUI
            loading={loading}
            handleLoading={handleLoading}
            branches={branches}
            drivers={driverList}
            dayOrders={dayOrders}
            zoom={zoom}
            coords={coords}
            mapcenter={latlng_center}
            loadDriverMap={loadDriverMap}
        />
    );
};

export default Drivers;