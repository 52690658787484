import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {store} from './redux/store';
import './main.css';
import './index.less';
import App from './App';
import reportWebVitals from './reportWebVitals';
import locale from 'antd/lib/locale/es_ES'
import { ConfigProvider } from 'antd';
import { register } from './utils/serviceWorker';

// const ipData = (await fetchIP())();
localStorage.setItem('ipInfo', '');

const container = document.getElementById('root');

ReactDOM.render(
<React.StrictMode>
  <ConfigProvider locale={locale} theme={{
            token: {
              // Seed Token
              colorPrimary: '#ff671d',
              borderRadius: 2,
            },
          }}>
      <Provider store={store}>
          <App />
      </Provider>
  </ConfigProvider>
</React.StrictMode>, 
container);

register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
