import { Tabs, Layout, Spin, Table, Space, Card, Col, Row, Statistic } from "antd";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import { FieldTimeOutlined, ShoppingOutlined, FundViewOutlined } from "@ant-design/icons";
import styles from "../styles/DeliverydashUI.module.css";

const { Header, Content, Sider } = Layout;

const { TabPane } = Tabs;

const DeliverydashUI = (props) => {
    const {
      modalOrder,
      loadingOrder,
      closeDetails,
      openDetails,
      loaded,
      isDriver,
      isAdmin,
      orderDetail,
      newOrders,
      dayOrders,
      // branches,
      getDriversBranch,
      openDriverConfirm,
      statistics,
    } = props;
    
    if (!loaded) {
      return (
        <div className={cx(styles.view, styles.loader)}>
          <Spin size="large" />
        </div>
      );
    }
  
    return (
      <div className={styles.view}>
        {!isDriver ? (
          <Row>
          <Col span={24}>
      <Header
        title="Ordenes Activas"
        actions={
          <div className={styles.ordersButtons}>
            {/* 
            <Link to="/promocion" className={styles.history}>
              <Child/>
              <span>PROMOCIÓN DÍA DEL NIÑO ({promosList.orders_total})</span>
            </Link>
            */}
          </div>
        }
      />
      <Tabs defaultActiveKey="2">
        {/* <TabPane tab={`EN MESA (${0})`} key="1">
          <Table columns={columnsEatIn} dataSource={[]} rowKey="id" />
        </TabPane> */}
        
        <TabPane tab={`DOMICILIO (${dayOrders.filter(x => x.orderType == 2).length})`} key="3">
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Row gutter={40} justify="center">
              <Col span={4} style={{textAlign: "center"}}><Card>
                <Statistic title="Tiempo Despacho" value={statistics.tiempoDespacho?moment(statistics.tiempoDespacho, 'LTS').format("mm[m] ss[s]"):"0m"} prefix={<FieldTimeOutlined />} /></Card>
              </Col>
              <Col span={4} style={{textAlign: "center"}}><Card>
                <Statistic title="Tiempo Entrega" value={statistics.tiempoFinaliza?moment(statistics.tiempoFinaliza, 'LTS').format("mm[m] ss[s]"):"0m"} prefix={<FieldTimeOutlined />} /></Card>
              </Col>
              <Col span={4} style={{textAlign: "center"}}><Card>
                <Statistic title="TQT PROM." value={statistics.tqt} prefix={<FundViewOutlined />} /></Card>
              </Col>
              <Col span={4} style={{textAlign: "center"}}><Card>
                <Statistic title="No. Ordenes" value={statistics.total_ordenes} prefix={<ShoppingOutlined />} /></Card>
              </Col>
            </Row>
          </Space>
        </TabPane>
      </Tabs>
      </Col>
      </Row>
        ) : (
  <Row>
           
          </Row>
        )}
        {/* <Modal
          destroyOnClose
          wrapClassName={styles.modalOrder}
          footer={false}
          visible={modalCommentaries}
          width={400}
          closable={false}
          onCancel={closeModalCommetaries}
        >
          <Commentaries
            close={closeModalCommetaries}
            finishOrder={finishOrder}
            cancelOrder={cancelOrder}
            type={modalCommentaries}
          />
        </Modal> */}
       
      </div>
    );
  };
  
  export default DeliverydashUI;