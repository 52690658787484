import instance from "./Request";

export const login = async (email, password, country) => {
  const request = await instance();
  let data = await request
    .post("/auth/partner", {
      email,
      password,
      country
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
