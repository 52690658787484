import { createSlice } from "@reduxjs/toolkit";
import { getProducts, changeActive, getExtras, changeExtraActive, changeSuggested } from "../../api/Products";

export const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
    extras:[],
    categories: [],
  },
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setExtras: (state, action) => {
      state.extras = action.payload;
    },
    setActive: (state, action) => {
      const { product, val, platform } = action.payload;
      const index = state.products.findIndex((el) => el.id === product);
      state.products[index][platform] = val;
    },

    setExtraActive: (state, action) => {
      const { extra, val, platform,  cat } = action.payload;
      if(cat === "157") {
        const index = state.extras.extras.findIndex((el) => el.id === extra);
        state.extras.extras[index][platform] = val;
      } else {
        const index = state.extras.extras2.findIndex((el) => el.id === extra);
        state.extras.extras2[index][platform] = val;
      }
    },

    setSuggested: (state, action) => {
      const { product, val } = action.payload;
      const index = state.products.findIndex((el) => el.id === product);
      state.products[index].suggested = val;
    },
    setRecomended: (state, action) => {
      const { product, val } = action.payload;
      const index = state.products.findIndex((el) => el.id === product);
      state.products[index].recomended = val;
    },
  },

});

// Action creators are generated for each case reducer function
export const { setProducts, setActive, setCategories, setExtras, setSuggested, setRecomended, setExtraActive } = productsSlice.actions;

export const getAllProducts = (branch) => async (dispatch) => {
  try {
    const response = await getProducts(branch);
    if (!response.error && response.status === 200) {
      const { categories } = response.data;
      let products = [];
      const productCategories = [];
      categories.forEach((category) => {
        products = products.concat(category.products);
        productCategories.push({ text: category.name, value: category.id });
      });
      dispatch(setProducts(products));
      dispatch(setCategories(productCategories));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const handleActive = (product, active, platform, branch) => async (dispatch) => {
  try {
    dispatch(setActive({ product, val: !active, platform, branch }));
    const response = await changeActive(product, !active, platform, branch);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      };
    }
    dispatch(setActive({ product, val: active, platform, branch }));
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    dispatch(setActive({ product, val: active, platform, branch }));
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const getAllExtras = (branch) => async (dispatch) => {
  try {
    const response = await getExtras(branch);
    if (!response.error && response.status === 200) {
      const { extras, extras2, extras3 } = response.data;
      //let extras = [];
      //infxtra.forEach((item) => {
      //  extras.push(item);
      //});
      const new_extras = {extras, extras2, extras3};
      dispatch(setExtras(new_extras));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const handleExtraActive = (extra, active, platform, branch,cat) => async (dispatch) => {
  try {
    dispatch(setExtraActive({ extra, val: !active, platform, branch, cat }));
    const response = await changeExtraActive(extra, !active, platform, branch, cat);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      };
    }
    dispatch(setExtraActive({ extra, val: active, platform, branch, cat }));
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    dispatch(setExtraActive({ extra, val: active, platform, branch, cat }));
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const handleExtra2Active = (extra, active, platform, branch, cat) => async (dispatch) => {
  try {
    dispatch(setExtraActive({ extra, val: !active, platform, branch, cat }));
    const response = await changeExtraActive(extra, !active, platform, branch, cat);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      };
    }
    dispatch(setExtraActive({ extra, val: active, platform, branch, cat }));
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    dispatch(setExtraActive({ extra, val: active, platform, branch, cat }));
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const handleSection = (product, active, platform, branch) => async (dispatch) => {
  try {
    dispatch(setSuggested({ product, val: !active, platform, branch }));
    const response = await changeSuggested(product, Number(!active), platform, branch);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      }; 
    }
    dispatch(setSuggested({ product, val: active, platform, branch }));
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    dispatch(setSuggested({ product, val: active, platform, branch }));
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const selectProducts = (state) => state.products.products;
export const selectCategories = (state) => state.products.categories;
export const selectExtras = (state) => state.products.extras;

export default productsSlice.reducer;
