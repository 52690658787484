import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Spin, Badge, Space, Button, Card } from 'antd';
import moment from 'moment-timezone';
import Header from "../../../components/HeaderList";
import ZoneMap from "../../../components/customMap";
import { ReactComponent as DriverOnline } from "../../../assets/icons/driver_online.svg";
import { ReactComponent as DriverOffline } from "../../../assets/icons/driver_offline.svg";
import { ReactComponent as DriverOrder } from "../../../assets/icons/driver_withOrder.svg";
import styles from "../styles/DriversUI.module.css";


const DriversUI = (props) => {
    const {
        loading,
        handleLoading,
        drivers,
        branches,
        dayOrders,
        zoom,
        coords,
        mapcenter
    } = props;
    const [driversMarkers, setDriversMarkers] = useState([]);
    const [branchesMarkers, setBranchesMarkers] = useState([]);
    const [showInfoDrivers, setShowInfoDrivers] = useState();
    const [showDrivers, setShowDrivers] = useState(false);
    const [selectedDriverMarker, setSelectedDriverMarker] = useState(null);

    useEffect(()=>{
        getDriversMarker();
        getBranchesMarker();
    }, [drivers]);

    const getDriversMarker = () => {
        let markers = [];
        if(drivers.filter(x => x.available==1).length > 0) {
            for(let drv of drivers.filter(x => x.available==1)) {
                var newMarker = {
                    id: drv.id,
                    title: drv.name,
                    address: '',
                    position: {lat: parseFloat(drv.latitude), lng: parseFloat(drv.longitude)},
                    icon: {
                        url: (require("../../../assets/icons/location-driver.svg")).default,
                    },
                    infowindowOpen: false,
                    markeroptions: {},
                    orders: drv.orders
                };
                markers.push(newMarker);
            }

            setDriversMarkers(markers);
        }
    };

    const getBranchesMarker = () => {
        let markers = [];
        if(branches.length > 0) {
            for(let branch of branches.filter(x => x.delivery==1)) {
                var newMarker = {
                    id: branch.id,
                    title: branch.zone,
                    address: branch.address,
                    position: {lat: parseFloat(branch.latitude), lng: parseFloat(branch.longitude)},
                    
                    icon: {
                        url:  (require("../../../assets/icons/location-branch.svg")).default
                    },
                    
                    infowindowOpen: false,
                    markeroptions: {}
                };
                markers.push(newMarker);
            }
            setBranchesMarkers(markers);
        }
    };

    const getStatus = (status) => {
        if (status === 6) {
            return "Orden nueva";
        } 
        else if (status === 7) {
            return "En preparación";
        } 
        else if (status === 10) {
            return "Lista para domicilio";
        } 
        else if (status === 11) {
            return "En camino";
        } 
        else if (status === 12) {
            return "Completada";
        }
        return "cancelada";
    };

    const countDriver = (status) => {
        let statusText;
        if(status == "online") {
            statusText =  (
                <>
                    <span>Disponible </span>
                    <strong>( {drivers.filter(x => x.available==1 && x.withActiveOrder==0).length} )</strong>
                </>
            );
        } 
        if(status == "offline") {
            statusText =  (
                <>
                    <span>Inactivo </span>
                    <strong>( {drivers.filter(x => x.available==0).length} )</strong>
                </>
            );
        }
        if(status == "withOrder") {
            statusText =  (
                <>
                    <span>Con Orden </span>
                    <strong>( {drivers.filter(x => x.available==1 && x.withActiveOrder==1).length} )</strong>
                </>
            );
        }
        return statusText;
    };

    const showDataDrivers = (e, status) => {
        let statusText;
        if(!showDrivers) {
            if(status == "online") {
                statusText =  <Space key="drivers_online" size={20} wrap className="badge-driver">
            {
                drivers.filter(x => x.available==1 && x.withActiveOrder==0).map( driver => (
                    <Card key={driver.id} size="small">
                        { driver.name }<br/><small>{moment(driver.updatedAt).tz('America/El_Salvador').fromNow()}</small>
                    </Card>
                ))
            }
            </Space>
            } 
            else if(status == "offline") {
                statusText =  <Space key="drivers_offline" size={20} wrap className="badge-driver">
            {
                drivers.filter(x => x.available==0).map( driver => (
                    <Card key={driver.id} size="small">
                        { driver.name }
                    </Card>
                ))
            }
            </Space>
            }
            else if(status == "withOrder") {
                statusText =  <Space key="drivers_withOrder" size={20} wrap className="badge-driver">
            {
                drivers.filter(x => x.available==1 && x.withActiveOrder==1).map( driver => (
                    <Card  key={driver.id} onClick={() => {loadDriverMap(driver)}} size="small">
                        { driver.name }<br/> 
                        <small>Ticket: {driver.orders.length > 1?driver.orders.map(d => d.ticket+", "):driver.orders[0].ticket}</small>
                    </Card>
                ))
            }
            </Space>
            }
            else {
                statusText =  <></>
            }
            setShowDrivers(true);
            setShowInfoDrivers(statusText);
        } 
        else {
            setShowDrivers(false);
        }
    };

    const loadDriverMap = (drv) => {
        var newMarker = {
            id: drv.id,
            title: drv.name,
            address: '',
            position: {lat: parseFloat(drv.latitude), lng: parseFloat(drv.longitude)},
            icon: {
                url: (require("../../../assets/icons/location-driver.svg")).default,
            },
            infowindowOpen: false,
            markeroptions: {},
            orders: drv.orders
        };
        setSelectedDriverMarker(newMarker);
    }  

    return (
        <div className={styles.view}>
            <Header
                back="/"
                title="DRIVERS"
            />
            {loading ? (
                <div className={styles.loader}>
                    <Spin size="large" />
                </div>
            ) : (
                <div>
                    <Row gutter={6}>
                        <Col span={24}>
                            <div>
                                <Button type="text" icon={<DriverOnline/>} onClick={e => showDataDrivers(e, "online")} className={styles.buttonCounter}><Badge status="success" text={countDriver("online")} style={{paddingRight: "0.5em"}} /></Button>
                                <Button type="text" icon={<DriverOffline/>} onClick={e => showDataDrivers(e, "withOrder")} className={styles.buttonCounter}><Badge status="warning" text={countDriver("withOrder")} style={{paddingRight: "0.5em"}} /></Button>
                                <Button type="text" icon={<DriverOrder/>} onClick={e => showDataDrivers(e, "offline")} className={styles.buttonCounter}><Badge status="error" text={countDriver("offline")} /></Button>
                            </div>
                            { showDrivers && showInfoDrivers }
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <ZoneMap
                                polygons={coords}
                                driversMarkers={driversMarkers}
                                selectedDriverMarker={selectedDriverMarker}
                                setSelectedDriverMarker={setSelectedDriverMarker}
                                branchesMarkers={branchesMarkers}
                                defaultCenter={mapcenter} 
                                centerZoom={zoom}
                                loadDraw={false}
                            />
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
};

export default DriversUI;