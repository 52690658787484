import { Tabs, Table, Switch, Spin, Tooltip } from "antd";
import { getColumnSearchProps } from "../../../utils/tables";
import { selectUser } from "../../../redux/slices/user";
import Header from "../../../components/HeaderList";
import styles from "../styles/CondimentUI.module.css";
import { useSelector } from "react-redux";

const { TabPane } = Tabs;

const CondimentUI = (props) => {
  const { loading, extras, handleExtrasActive, handleExtras2Active } = props;

  const user = useSelector(selectUser);

  const columns = [
    /* {
      title: "ID",
      dataIndex: "id",
      align: "center",
    }, */
    {
      title: "Nombre",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Categoría",
      dataIndex: "id_category",
      align: "center",
    },
    {
      title: "Precio",
      dataIndex: "price",
      align: "center",
      render: (val) => `$${Number(val).toFixed(2)}`,
    },
    {
      title: "Take Out",
      align: "center",
      dataIndex: "take_out",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.take_out === value,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={() => handleExtrasActive(record.id, val, "take_out", user.branch, "157")}
        />
      ),
    },
    {
      title: "Eat In",
      align: "center",
      dataIndex: "eat_in",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.eat_in === value,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={() => handleExtrasActive(record.id, val, "eat_in", user.branch, "157")}
        />
      ),
    },
  ];

  const columns2 = [
    /* {
      title: "ID",
      dataIndex: "id",
      align: "center",
    }, */
    {
      title: "Nombre",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Categoría",
      dataIndex: "id_category",
      align: "center",
    },
    {
      title: "Precio",
      dataIndex: "price",
      align: "center",
      render: (val) => `$${Number(val).toFixed(2)}`,
    },
    {
      title: "Take Out",
      align: "center",
      dataIndex: "take_out",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.take_out === value,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={() => handleExtrasActive(record.id, val, "take_out", user.branch, "239")}
        />
      ),
    },
    {
      title: "Eat In",
      align: "center",
      dataIndex: "eat_in",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.eat_in === value,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={() => handleExtrasActive(record.id, val, "eat_in", user.branch, "239")}
        />
      ),
    },
  ];

  const columns3 = [
    /* {
      title: "ID",
      dataIndex: "id",
      align: "center",
    }, */
    {
      title: "Nombre",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Categoría",
      dataIndex: "id_category",
      align: "center",
    },
    {
      title: "Precio",
      dataIndex: "price",
      align: "center",
      render: (val) => `$${Number(val).toFixed(2)}`,
    },
    {
      title: "Take Out",
      align: "center",
      dataIndex: "take_out",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.take_out === value,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={() => handleExtras2Active(record.id, val, "take_out", user.branch, "239")}
        />
      ),
    },
    {
      title: "Eat In",
      align: "center",
      dataIndex: "eat_in",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.eat_in === value,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={() => handleExtras2Active(record.id, val, "eat_in", user.branch, "239")}
        />
      ),
    },
  ];

  return (
    <div className={styles.view}>
      <Header title="Salsas" />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Salsas" key="1">
              <Table columns={columns} dataSource={extras.extras} rowKey="id" />
            </TabPane>
            <TabPane tab="Picante" key="2">
              <Table columns={columns2} dataSource={extras.extras2} rowKey="id" />
            </TabPane>
            <TabPane tab="Alas/Chuncks" key="3">
              <Table columns={columns3} dataSource={extras.extras3} rowKey="id" />
            </TabPane>
          </Tabs>
          
        </div>
      )}
    </div>
  );
};

export default CondimentUI;
