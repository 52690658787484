import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {selectUser} from './redux/slices/user';
import Dashboard from './pages/dashboard/Main';
import Login from './pages/login/Main';
import { fetchIP } from './utils/IpContext';
import { setupNotifications } from './utils/firebaseConfig';
import { toastNotification, sendNativeNotification } from './utils/notificationHelpers';
import useVisibilityChange from './utils/useVisibilityChange';
import { register } from './utils/serviceWorker';

const App = () => {
  const isForeground = useVisibilityChange();
  const user = useSelector(selectUser);
  const [country, setCountry] = useState('');

  useEffect( () => {
    async function initialFetch() {
      // Verificar si ya tenemos el país almacenado en localStorage
      const storedCountry = localStorage.getItem('ipInfo');
      if (storedCountry) {
        setCountry(storedCountry);
      } else {
        // Función para obtener la IP del usuario
        await fetchIP();
      }
    }
    initialFetch();
    setupNotifications((message) => {
      if (isForeground) {
        // App is in the foreground, show toast notification
        toastNotification({
          title: message.title,
          description: message.body,
          status: "info",
        });
      } else {
        // App is in the background, show native notification
        sendNativeNotification({
          title: message.title,
          body: message.body,
        });
      }
    });
  }, []); 

  return (
    <Router>
      <Routes>
        <Route path="/*" element={user ? <Dashboard /> : <Login />} />
      </Routes>
    </Router>
  );
}

export default App;
