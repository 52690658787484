import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyBQpQ8p2zo5t3rY5xGHGGdWQKDiirJzMc8",
    authDomain: "fcm-reactjs-68c6c.firebaseapp.com",
    databaseURL: "https://fcm-reactjs-68c6c.firebaseio.com",
    projectId: "fcm-reactjs-68c6c",
    storageBucket: "fcm-reactjs-68c6c.appspot.com",
    messagingSenderId: "700915970433",
    appId: "1:700915970433:web:e382ce0106fdbb77ebf880",
    measurementId: "G-LCZHWFH69E"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const setupNotifications = async () => {
    try {
        // Request permission for notifications
        const permission = await Notification.requestPermission();
        
        if (permission === 'granted') {
            console.log('Notification permission granted.');
        // Get the FCM token
            const token = await getToken(messaging);
            console.log('FCM Token:', token);
        } else {
            console.log('Notification permission denied.');
        }
        // Handle foreground notifications
        onMessage(messaging, (payload) => {
            console.log('Foreground Message:', payload);
        // Handle the notification or update your UI
        });
    } catch (error) {
        console.error('Error setting up notifications:', error);
    }
};

export { messaging, setupNotifications };