import { Table, Modal, Spin, Button, DatePicker, Input } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import cx from "classnames";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as Cash } from "../../../assets/icons/cash.svg";
import { ReactComponent as Card } from "../../../assets/icons/card.svg";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { ReactComponent as Cancel } from "../../../assets/icons/cancel.svg";
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg";
import { getColumnSearchProps } from "../../../utils/tables";
import OrderDetail from "../../../components/OrderDetail";
import { ReactComponent as Calendar } from "../../../assets/icons/calendar.svg";
import styles from "../styles/ReportUI.module.css";

const { RangePicker } = DatePicker;
const { Search } = Input;

const ReportUI = (props) => {
  const {
    loading,
    history,
    order,
    openOrder,
    closeOrder,
    exportData,
    beginDate,
    endDate,
    handleDates,
    orderSearch,
    loadingOrder,
  } = props;

  const getIcon = (status) => {
    if (status === 15) {
      return <Cancel className={styles.driverIcon} />;
    }
    return <Check />;
  };

  const getMethod = (method) => {
    if (method === 1) {
      return <Cash />;
    }
    return <Card />;
  };

  const columns = [
    {
      title: "Ticket",
      dataIndex: "ticket",
      align: "center",
      ...getColumnSearchProps("ticket"),
    },
    {
      title: "Sucursal",
      dataIndex: "branch",
      align: "center",
      render: (val) => val.zone || "-",
    },
    
    {
      title: "Cliente",
      dataIndex: "id_user",
      render: (val) => `${val.name} ${val.lastname}`,
    },
    {
      title: "Monto",
      dataIndex: "total",
      render: (val) => `$${parseFloat(val).toFixed(2)}`,
      align: "center",
    },
    {
      title: "Método",
      dataIndex: "payment_method",
      render: (val) => getMethod(val),
      align: "center",
      filters: [
        {
          value: 1,
          text: "Efectivo",
        },
        {
          value: 2,
          text: "Tarjeta",
        },
      ],
      onFilter: (value, record) => record.payment_method === value,
      filterMultiple: false,
    },
    {
      title: "Estado",
      dataIndex: "status",
      render: (val) => getIcon(val),
      align: "center",
      filters: [
        {
          value: 12,
          text: "Completadas",
        },
        {
          value: 15,
          text: "Canceladas",
        },
      ],
      onFilter: (value, record) => record.status === value,
      filterMultiple: false,
    },
    {
      title: "Fecha",
      dataIndex: "created_time",
      render: (val) => moment(val).format("DD/MM/YY"),
      align: "center",
    },
    {
      title: "Hora Ingreso",
      dataIndex: "created_time",
      align: "center",
      render: (val) => moment(val).format("hh:mma"),
    },
    {
      title: "Hora Despachando",
      dataIndex: "fechaStatusCamino",
      align: "center",
      render: (val) => moment(val).format("hh:mma"),
    },
    {
      title: "Hora Finalizada",
      dataIndex: "fechaStatusFin",
      align: "center",
      render: (val) => moment(val).format("hh:mma"),
    },
    {
      title: "Tiempo Despacho",
      dataIndex: "tiempoDespacho",
      align: "center",
      render: (val) => moment(val, 'LTS').format("mm[m] ss[s]"),
    },
    {
      title: "Tiempo Entrega",
      dataIndex: "tiempoFinaliza",
      align: "center",
      render: (val) => moment(val, 'LTS').format("mm[m] ss[s]"),
    },
  ];

  return (
    (<div className={styles.wrapper}>
      <div className={styles.header}>
        <Link to={"/"} className={styles.back}>
          <div className={styles.backButton}>
            <Arrow />
          </div>
          <span>Ordenes</span>
        </Link>
        <div>
          <Search
            placeholder="Buscar por código"
            className={cx(styles.input, styles.search)}
            allowClear
            enterButton
            size="large"
            loading={loading}
            onSearch={orderSearch}
          />
          <RangePicker
            size="large"
            suffixIcon={<Calendar />}
            className={styles.picker}
            placeholder={["Fecha Inicio", "Fecha Final"]}
            allowClear={false}
            value={[beginDate, endDate]}
            onChange={handleDates}
          />
          <Button
            disabled={loading}
            size="large"
            type="primary"
            onClick={exportData}
          >
            Exportar
          </Button>
        </div>
      </div>
      {!loading ? (
        <div className={styles.orders}>
          <Table dataSource={history} columns={columns} rowKey="id" />
        </div>
      ) : (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      )}
      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        open={order}
        width={1150}
        closable={false}
        onCancel={closeOrder}
      >
        <OrderDetail order={order} close={closeOrder} />
      </Modal>
    </div>)
  );
};

export default ReportUI;
