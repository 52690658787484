import React, {  useEffect } from 'react'
import scanIcon from '../../../assets/icons/scan-icon.svg'
import listIcon from '../../../assets/icons/list-icon.svg'
import { useNavigate } from 'react-router-dom'
import LayoutRounded from '../../../components/LayoutRounded'
import recentlyIcon from '../../../assets/icons/recently-icon.svg'
import {  Modal, Spin, Table } from 'antd'
import HeaderList from '../../../components/HeaderList'
import { useDispatch, useSelector } from 'react-redux'
import { getLastScannedThunk } from '../../../redux/slices/scanner'
import { getColumnSearchProps } from "../../../utils/tables";
import moment from "moment";
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg";
import styles from "../../monitor/styles/MonitorUI.module.css";
import OrderDetail from '../../../components/OrderDetail'

const ScannerUI = ({
  loadingOrder,
  openDetails,
  modalOrder,
  closeDetails,
  orderDetail,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const lastScanned = useSelector((state) => state.scanner.lastScanned);
  const userScanned = useSelector(state => state.scanner.userScanned)



  const columnsTakeOut = [
    {
      title: "Ticket",
      dataIndex: "ticket",
      ...getColumnSearchProps("ticket"),
      render: (val, record) => <span>{val}</span>,
    },
    {
      title: "Cliente",
      dataIndex: "id_user",
      render: (val) => `${val.name} ${val.lastname}`,
    },
    {
      title: "Sucursal",
      dataIndex: "branch",
      align: "center",
      render: (val) => val.zone || "-",
    },
    {
      title: "Hora de redención",
      dataIndex: "redeem_time",
      align: "center",
      render: (val) => moment(val).format("DD/MM/YYYY hh:mm a"),
    },
    {
      title: "Teléfono",
      dataIndex: "id_user",
      align: "center",
      render: (val) => val.mobile,
    },
    {
      title: "Monto actual",
      dataIndex: "total",
      align: "center",
      render: (val) => `$${val}`,
    },
    {
      title: "Ver detalle",
      dataIndex: "id",
      align: "center",
      render: (val) => (
        <button
          disabled={loadingOrder}
          onClick={() => openDetails(val)}
          className={styles.eye}
        >
          {loadingOrder === val ? <Spin size="small" /> : <Eye />}
        </button>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      getLastScannedThunk({ 
        id_user: user.info.id, 
        idBranch: user.info.branch 
      })
    );
  }, []);

  useEffect(() => {
    if(!userScanned) return
    console.log(userScanned);
    openDetails(userScanned.id)
  }, [userScanned])

  return (
    (<main>
      <HeaderList title="Scanner" />
      <LayoutRounded>
        <h2 className="text-darkGray text-[22px] xl:text-[34px] font-bold mb-[44px]">
          ¡Bienvenido!
        </h2>

        <div className="flex flex-col xl:flex-row justify-center gap-[1.5rem] items-center">
          <div
            className="bg-darkRed cursor-pointer xl:h-[109.55px] w-[294px] xl:w-[449.75px] flex h-[72px] items-center text-white rounded-[16px] px-[1rem] gap-[1rem]"
            onClick={() => navigate("/home-scanner/scanner")}
          >
            <img className=" xl:w-[68px]" src={scanIcon} alt="" />
            <p className="xl:text-[24px]">Escanear código</p>
          </div>

          <div
            className="cursor-pointer  xl:h-[109.55px] w-[294px] xl:w-[449.75px] flex h-[72px] items-center border-2 border-darkRed rounded-[16px] px-[1rem] gap-[1rem]"
            onClick={() => navigate("/home-scanner/lista-escaneados")}
          >
            <img className="xl:w-[68px]" src={listIcon} alt="" />
            <p className="text-darkGray  xl:text-[24px]">Lista de redimidos</p>
          </div>
        </div>

        <div className="w-[75%] mx-auto mt-[3rem] ">
          <header className="flex gap-[10px] items-center text-darkRed font-medium mb-[20px]">
            <img src={recentlyIcon} alt="" />
            <p>Últimos redimidos</p>
          </header>

          <Table
            columns={columnsTakeOut}
            dataSource={lastScanned}
            scroll={{ x: "100%" }}
            rowKey={record => record.id}
          />
        </div>
      </LayoutRounded>
      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        open={modalOrder}
        width={1150}
        closable={false}
        onCancel={closeDetails}
      >
        <OrderDetail
          order={orderDetail}
          close={closeDetails}
        />
      </Modal>
    </main>)
  );
};

export default ScannerUI
